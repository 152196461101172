// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   // BaseUrl: "http://logic-efa1ed61.localhost.run/v1/api/"
//   BaseUrl: "http://192.168.1.81:3000/api/v1/",
//   AuthUrl: "http://auth.restjson.com/api/auth/",
//   WebUrl: "http://api.restjson.com/api/v1/schema/",
//   SiteUrl: "http://restjson.com",
// };
export const environment = {
  production: false,
  BaseUrl: "https://restjson.com/",
  AuthUrl: "https://auth.restjson.com/api/auth/",
  WebUrl: "https://api.restjson.com/v1/",
  SiteUrl: "https://restjson.com",
  WebUrlLanding: "www.api.restjson.com/v1/",
};
