import { Injectable } from "@angular/core";
// import { AuthRequestService } from './services/auth_request.services';
import { Router } from "@angular/router";

import { JwtHelperService } from "@auth0/angular-jwt";
import { NGXToastrService } from "./../shared/services/toastr.service";

@Injectable()
export class AuthService {
  token: string;

  constructor(public router: Router, public toastrservice: NGXToastrService) {}

  logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
    this.toastrservice.typeInfo("Successful", "Logout");
    document.body.classList.remove("vertical-menu");
  }

  login(token) {
    localStorage.setItem("access_token", token?.access_token?.token);
    localStorage.setItem("refresh_token", token?.refresh_token?.token);
    this.router.navigate(["/user/resources"]);
    this.toastrservice.typeSuccess("Successful", "Login");
  }

  isAuthenticated() {
    const token = localStorage.getItem("access_token");
    if (token) {
      const helper = new JwtHelperService();
      if (!helper.isTokenExpired(token)) {
        return true;
      }
    }
    return false;
    // here you can check if user is authenticated or not through his token
  }
}
