import { Routes, RouterModule } from '@angular/router';



//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const PAGES_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('./../../pages/pages.module').then(m => m.PagesModule)
    },


];
