import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { PAGES_ROUTES } from './shared/routes/pages.routes';


import { AuthGuard } from './auth/auth-guard.service';

const appRoutes: Routes = [
 
  { path: 'user', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  { path: '', data: { title: 'full Views' }, children: PAGES_ROUTES },

  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
