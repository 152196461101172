import { RouteInfo } from "../vertical-menu/vertical-menu.metadata";

export const HROUTES: RouteInfo[] = [
  // {
  //     path: '/full-layout', title: 'Dashboard', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: "/user/resources",
    title: "My Resources",
    icon: "ft-layout",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  // {
  //     path: '/full-layout', title: 'Datasets', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: "/user/plans",
    title: "Plans",
    icon: "ft-layout",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/user/documentation",
    title: "Documentation",
    icon: "ft-layout",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/user/getting-started",
    title: "Getting Started",
    icon: "ft-layout",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  // {
  //     path: '/user/invoices', title: 'Invoice', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: "/user/profile",
    title: "Profile",
    icon: "ft-layout",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
];
